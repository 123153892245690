const Colors = {
  white: '#ffffff',
  light: '#f6f1ed',
  lightGrey: '#e5dada',
  dark: '#b5a397',
  darker: '#6a5750',
  darkest: '#000000',
  closeIcon: '#969ea4',
  black: '#000000'
};

export default Colors;
