import React from 'react';
import styled from 'styled-components';
import Colors from '../Colors';
import { Content } from './Layout';
import { media } from '../MediaQueries';
import {FaFacebook, FaInstagram, FontAwesome} from "react-icons/fa";
import { Link } from 'gatsby';
import { render } from 'react-dom';


const FooterWrapper = styled.div`
  background: ${Colors.black};
  color: ${Colors.white};
  padding: ${props => (props.big ? '210px' : props.medium ? '100px' : '80px')} 0 60px 0;
  ${media.tablet`padding: ${props => (props.big ? '150px' : props.medium ? '100px' : '128px')} 20px 50px 20px;`}
`;

const Copyright = styled.p`
  text-align: center;
`;

const Icons = styled.p`
text-align: center;
marginLeft: 25;
`;




const Footer = ({ big, medium }) => (
  <FooterWrapper {...big && { big }} {...medium && { medium }}>
    <Content>
    <Icons><Link to="https://www.instagram.com/remcoweb/" white align="center" >
      <FaInstagram size="50" color="white"/>
    </Link>
    <Link to="https://www.facebook.com/remcoweb" white align="center" >
      <FaFacebook size="50" color="white" style={{marginLeft: 20}}/>
      </Link></Icons>
      <Copyright white align="center">
      
        © Remco Web 2021. All rights reserved.
      </Copyright>
      
      
    </Content>
  </FooterWrapper>
);

export default Footer;
